import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-noprod/web-sunrise-landing-deploy-dev/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { debounce } from "lodash-es";
import { LoggerInstance } from "@vfit/shared/providers";
import { createSyntheticEvent, useAnimation } from "@vfit/shared/hooks";
import { useInputs } from "../../inputs.hook";
import { getGeocoderResultByPlaceId, getGeocoderResults, getPlacePredictions, parseGeocoderResult } from "./geoAddressInput.utils";
export var useGeoAddressInput = function(param) {
    var _error = param._error, _disabled = param._disabled, _onGeolocation = param._onGeolocation, country = param.country, loading = param.loading, onChange = param.onChange, ref = param.ref, setInternalError = param.setInternalError, setLoading = param.setLoading;
    var ref1 = useState([]), geoPredictions = ref1[0], setGeoPredictions = ref1[1];
    var ref2 = useState(false), notDisableIfLoading = ref2[0], setNotDisableIfLoading = ref2[1];
    var ref3 = useState(false), showStreetNumber = ref3[0], setShowStreetNumber = ref3[1];
    var containerRef = useRef(null);
    var innerRef = useRef(null);
    var formattedAddressRef = useRef(null);
    var streetNumberRef = useRef(null);
    var ref4 = useInputs({
        _disabled: _disabled,
        _error: _error,
        loading: loading,
        notDisableIfLoading: notDisableIfLoading
    }), disabled = ref4.disabled, error = ref4.error, id = ref4.id;
    var streetNumberA = useAnimation({
        prefix: "street-number",
        show: showStreetNumber
    });
    var items = useMemo(function() {
        return Array.from(new Set(geoPredictions.map(function(predictionOrGeocoderResult) {
            return predictionOrGeocoderResult.description || predictionOrGeocoderResult.formatted_address;
        })));
    }, [
        geoPredictions
    ]);
    var refCallback = useCallback(function(node) {
        if (node) {
            innerRef.current = node;
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    ref.current = node;
                }
            }
        }
    }, [
        ref
    ]);
    var resetInnerRefValue = useCallback(function() {
        if (innerRef.current) {
            innerRef.current.value = "";
            var syntheticEvent = createSyntheticEvent(innerRef.current, "change");
            if (syntheticEvent) {
                onChange === null || onChange === void 0 ? void 0 : onChange(syntheticEvent);
            }
        }
    }, [
        onChange
    ]);
    var onClearCallback = useCallback(function() {
        if (innerRef.current) {
            setGeoPredictions([]);
            setShowStreetNumber(false);
            resetInnerRefValue();
        }
    }, [
        resetInnerRefValue
    ]);
    var getPredictions = useCallback(function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(value) {
            var predictions, indexOfSelectedPrediction, index, geocoderResult, geoAddress, city, postalCode, province, region, street, streetNumber, latitude, longitude, placeId, syntheticEvent;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        if (!(formattedAddressRef.current && innerRef.current)) {
                            _ctx.next = 42;
                            break;
                        }
                        if (!(value.length > 4)) {
                            _ctx.next = 41;
                            break;
                        }
                        setNotDisableIfLoading(true);
                        setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
                        _ctx.prev = 4;
                        _ctx.next = 7;
                        return getPlacePredictions(value, country);
                    case 7:
                        predictions = _ctx.sent;
                        setGeoPredictions(predictions || []);
                        indexOfSelectedPrediction = predictions ? predictions.findIndex(function(param) {
                            var description = param.description;
                            var ref;
                            return description === ((ref = formattedAddressRef.current) === null || ref === void 0 ? void 0 : ref.value);
                        }) : -1;
                        if (!(predictions && (indexOfSelectedPrediction >= 0 || (predictions === null || predictions === void 0 ? void 0 : predictions.length) === 1))) {
                            _ctx.next = 30;
                            break;
                        }
                        index = indexOfSelectedPrediction >= 0 ? indexOfSelectedPrediction : 0;
                        formattedAddressRef.current.value = predictions[index].description;
                        setGeoPredictions([
                            predictions[index]
                        ]);
                        _ctx.next = 16;
                        return getGeocoderResultByPlaceId(predictions[index].place_id, country);
                    case 16:
                        geocoderResult = _ctx.sent;
                        if (!geocoderResult) {
                            _ctx.next = 27;
                            break;
                        }
                        geoAddress = parseGeocoderResult(geocoderResult);
                        city = geoAddress.city, postalCode = geoAddress.postalCode, province = geoAddress.province, region = geoAddress.region, street = geoAddress.street, streetNumber = geoAddress.streetNumber, latitude = geoAddress.latitude, longitude = geoAddress.longitude, placeId = geoAddress.placeId;
                        if (!(!city || !postalCode || !province || !region || !street || !latitude || !longitude || !placeId)) {
                            _ctx.next = 22;
                            break;
                        }
                        throw new Error("Invalid geoAddress");
                    case 22:
                        innerRef.current.value = JSON.stringify(geoAddress);
                        syntheticEvent = createSyntheticEvent(innerRef.current, "change");
                        if (syntheticEvent) {
                            onChange === null || onChange === void 0 ? void 0 : onChange(syntheticEvent);
                        }
                        if (!streetNumber && city && postalCode && province && region && street && formattedAddressRef.current !== document.activeElement) {
                            setShowStreetNumber(true);
                        } else {
                            setShowStreetNumber(false);
                        }
                        // This is a trick to force an update in the input
                        setTimeout(function() {
                            var ref, ref1;
                            (ref = innerRef.current) === null || ref === void 0 ? void 0 : ref.focus();
                            (ref1 = innerRef.current) === null || ref1 === void 0 ? void 0 : ref1.blur();
                        }, 1);
                    case 27:
                        // This is a trick to force an update in the input
                        setTimeout(function() {
                            var ref, ref1;
                            (ref = formattedAddressRef.current) === null || ref === void 0 ? void 0 : ref.focus();
                            (ref1 = formattedAddressRef.current) === null || ref1 === void 0 ? void 0 : ref1.blur();
                        }, 1);
                        _ctx.next = 31;
                        break;
                    case 30:
                        {
                            resetInnerRefValue();
                            // This is a trick to open the select
                            setTimeout(function() {
                                var ref;
                                (ref = formattedAddressRef.current) === null || ref === void 0 ? void 0 : ref.click();
                            }, 1);
                        }
                    case 31:
                        _ctx.next = 37;
                        break;
                    case 33:
                        _ctx.prev = 33;
                        _ctx.t0 = _ctx["catch"](4);
                        resetInnerRefValue();
                        setInternalError === null || setInternalError === void 0 ? void 0 : setInternalError();
                    case 37:
                        setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
                        setNotDisableIfLoading(false);
                        _ctx.next = 42;
                        break;
                    case 41:
                        {
                            setGeoPredictions([]);
                            resetInnerRefValue();
                        }
                    case 42:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee, null, [
                [
                    4,
                    33
                ]
            ]);
        }));
        return function(value) {
            return _ref.apply(this, arguments);
        };
    }(), [
        country,
        onChange,
        resetInnerRefValue,
        setInternalError,
        setLoading
    ]);
    var debouncedGetPredictions = useMemo(function() {
        return debounce(getPredictions, 500);
    }, [
        getPredictions
    ]);
    var handleFormattedAddressChange = useCallback(function(e) {
        setShowStreetNumber(false);
        debouncedGetPredictions(e.target.value.trim());
    }, [
        debouncedGetPredictions
    ]);
    var setFormattedAddress = useCallback(function(_streetNumber) {
        if (innerRef.current && formattedAddressRef.current) {
            if (innerRef.current.value) {
                try {
                    var ref = JSON.parse(innerRef.current.value), city = ref.city, postalCode = ref.postalCode, province = ref.province, region = ref.region, street = ref.street, streetNumberFromInnerInput = ref.streetNumber;
                    var streetNumber = _streetNumber !== undefined ? _streetNumber : streetNumberFromInnerInput;
                    /* Here the required field are only:
             * city, postalCode, province, region and street.
             * When this function is triggered to setting the default value
             * it is also possible adding a value without:
             * streetNumber, latitude, longitude and placeId.
             * These fields will be calculated automatically. */ if (!city || !postalCode || !province || !region || !street) {
                        throw new Error("Invalid default value for geoAddress");
                    }
                    if (!streetNumber && formattedAddressRef.current !== document.activeElement) {
                        setShowStreetNumber(true);
                    }
                    formattedAddressRef.current.value = "".concat(street, ", ").concat(streetNumber ? "".concat(streetNumber, ", ") : "").concat(postalCode, ", ").concat(city, ", ").concat(province, ", ").concat(region);
                } catch (_e) {
                    formattedAddressRef.current.value = "";
                    resetInnerRefValue();
                }
            }
            var syntheticEvent = createSyntheticEvent(formattedAddressRef.current, "change");
            if (syntheticEvent) {
                handleFormattedAddressChange === null || handleFormattedAddressChange === void 0 ? void 0 : handleFormattedAddressChange(syntheticEvent);
            }
            // This is a trick to force an update in the input
            setTimeout(function() {
                var ref, ref1;
                (ref = formattedAddressRef.current) === null || ref === void 0 ? void 0 : ref.focus();
                (ref1 = formattedAddressRef.current) === null || ref1 === void 0 ? void 0 : ref1.blur();
            }, 1);
        }
    }, [
        handleFormattedAddressChange,
        resetInnerRefValue
    ]);
    var debouncedSetFormattedAddress = useMemo(function() {
        return debounce(setFormattedAddress, 1000);
    }, [
        setFormattedAddress
    ]);
    var handleStreetNumberChange = useCallback(function(e) {
        debouncedSetFormattedAddress(e.target.value.trim());
    }, [
        debouncedSetFormattedAddress
    ]);
    var onGeolocation = useCallback(function() {
        _onGeolocation === null || _onGeolocation === void 0 ? void 0 : _onGeolocation();
        try {
            if ("geolocation" in navigator) {
                setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
                navigator.geolocation.getCurrentPosition(function() {
                    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(position) {
                        var results;
                        return regeneratorRuntime.wrap(function _callee$(_ctx) {
                            while(1)switch(_ctx.prev = _ctx.next){
                                case 0:
                                    _ctx.prev = 0;
                                    _ctx.next = 3;
                                    return getGeocoderResults(position.coords.latitude, position.coords.longitude, country);
                                case 3:
                                    results = _ctx.sent;
                                    if (results) {
                                        setGeoPredictions(results);
                                        // This is a trick to open the select
                                        setTimeout(function() {
                                            var ref;
                                            (ref = formattedAddressRef.current) === null || ref === void 0 ? void 0 : ref.click();
                                        }, 1);
                                    }
                                    setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
                                    _ctx.next = 13;
                                    break;
                                case 8:
                                    _ctx.prev = 8;
                                    _ctx.t0 = _ctx["catch"](0);
                                    LoggerInstance.debug("Geolocation error");
                                    setInternalError === null || setInternalError === void 0 ? void 0 : setInternalError();
                                    setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
                                case 13:
                                case "end":
                                    return _ctx.stop();
                            }
                        }, _callee, null, [
                            [
                                0,
                                8
                            ]
                        ]);
                    }));
                    return function(position) {
                        return _ref.apply(this, arguments);
                    };
                }(), function() {
                    LoggerInstance.debug("Unable to retrieve the user's geolocation");
                    setInternalError === null || setInternalError === void 0 ? void 0 : setInternalError();
                    setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
                }, {
                    enableHighAccuracy: true,
                    maximumAge: 6000,
                    timeout: 10000
                });
            } else {
                LoggerInstance.debug("Geolocation IS NOT available");
                setInternalError === null || setInternalError === void 0 ? void 0 : setInternalError();
            }
        } catch (_e) {
            LoggerInstance.debug("Geolocation error");
            setInternalError === null || setInternalError === void 0 ? void 0 : setInternalError();
            setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
        }
    }, [
        _onGeolocation,
        country,
        setInternalError,
        setLoading
    ]);
    // Handle default value.
    useEffect(function() {
        setFormattedAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        containerRef: containerRef,
        disabled: disabled,
        error: error,
        formattedAddressRef: formattedAddressRef,
        handleFormattedAddressChange: handleFormattedAddressChange,
        handleStreetNumberChange: handleStreetNumberChange,
        id: id,
        items: items,
        notDisableIfLoading: notDisableIfLoading,
        onClearCallback: onClearCallback,
        onGeolocation: onGeolocation,
        refCallback: refCallback,
        streetNumberA: streetNumberA,
        streetNumberRef: streetNumberRef
    };
};
